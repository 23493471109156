import { useEffect } from 'react';
import Cookies from 'js-cookie';

const useUserId = () => {
    useEffect(() => {
        // Check if the cookie exists, if not, create one
        if (!Cookies.get('user_id')) {
            const userId = Math.random().toString(36).substring(2, 15);
            Cookies.set('user_id', userId, { expires: 365 });
        }
    }, []);

    return Cookies.get('user_id');
};

export {useUserId};