import React from 'react';
import sparkles from "../img/sparklesicon.svg";
import avatar from "../img/avatar.png";
import star from "../img/staricon.svg";
import posthog from 'posthog-js'; // Import PostHog

function Modal({ isOpen, onClose, message }) {
    if (!isOpen) {
        return null;
    }

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const handleUpgradeClick = () => {
        posthog.capture('Upgrade Now Clicked');
    };

    return (
        <div className='modal-overlay' onClick={handleOverlayClick}>
            <div className='modal-content'>
                <div className='modal-head'>
                    <h2 className='heading2'>Upgrade your cold outreach</h2>
                    <p className='modal-p'>{message}</p>
                </div>
                <div className='modal-center'>
                    <ul className="list">
                        <li className="list-item">CSV bulk leads upload</li>
                        <li className="list-item">Upload multiple leads at once</li>
                        <li className="list-item">Personalised icebreakers based on Twitter (𝕏)</li>
                        <li className="list-item">Generate 10s of icebreakers in seconds</li>
                        <li className="list-item">Improve reply rate</li>
                        <li className="list-item">Improve open rate</li>
                    </ul>
                </div>
                <div className='modal-footer'>
                    <a className='big main-button' href='https://app.icebreaker.email/register' onClick={handleUpgradeClick}>
                        <img className='n-icon' src={sparkles} alt='sparkles' />
                        Upgrade Now – $30/month
                    </a>
                    <div className='feedback'>
                        <div className='avatar'>
                            <img className='n-icon' src={avatar} alt='avatar' width={40} />
                        </div>
                        <div className='feedback-content'>
                            <div className='stars'>
                                <img src={star} alt='star' />
                                <img src={star} alt='star' />
                                <img src={star} alt='star' />
                                <img src={star} alt='star' />
                                <img src={star} alt='star' />
                            </div>
                            <p className='feedback-text'>
                                Our reply rates have
                                <span className='feedback-span'> increased up to 8 times </span>
                                since we started using Icebreaker
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;