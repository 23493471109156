import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const useUsageCount = () => {
    const [usageCount, setUsageCount] = useState(0);

    useEffect(() => {
        const count = Cookies.get('usage_count');
        if (count) {
            setUsageCount(parseInt(count, 10));
        } else {
            Cookies.set('usage_count', '0', { expires: 365 });
        }
    }, []);

    const incrementUsageCount = () => {
        const newCount = usageCount + 1;
        setUsageCount(newCount);
        Cookies.set('usage_count', newCount.toString(), { expires: 365 });
    };

    return [usageCount, incrementUsageCount];
};

export default useUsageCount;