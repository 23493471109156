import React, { useState } from 'react';
import sparkles from "../img/sparklesicon.svg";
import { useUserId } from '../hooks/useUserId';
import Modal from './Modal'; // Import the Modal component
import posthog from 'posthog-js'; // Import PostHog
import { toast } from 'sonner';

function FormComponent({ onSubmit, incrementUsageCount, usageCount, usageLimit }) {
    const [input1, setInput1] = useState('');
    const [input2, setInput2] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
    const [isLoading, setIsLoading] = useState(false); // State to manage loading spinner
    const userId = useUserId();

    const handleSubmit = (event) => {
        event.preventDefault();
        if (usageCount < usageLimit) {
            const lowerCaseInput2 = input2.toLowerCase(); // Convert input2 to lowercase

            const twitterDomains = /^(https?:\/\/)?(www\.)?(twitter\.com|x\.com|t\.co)(\/|$)/;
            if (twitterDomains.test(lowerCaseInput2)) {
                toast.error("Can't read Twitter URL in Demo mode."); // Show error toast
                return; // Exit the function without proceeding
            }

            setIsLoading(true); // Set loading state to true
            const formattedInput2 = formatURL(lowerCaseInput2);
            const submissionId = Math.random().toString(36).substring(2, 15); // Generate a unique submission ID
            const formData = { input1, input2: formattedInput2, id: userId, submissionId };

            console.log('Submitting form:', formData);

            // Send POST request to Flask backend
            fetch('/data', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            })
            .then(res => {
                if (!res.ok) {
                    throw new Error('Server response was not ok.');
                }
                return res.json();
            })
            .then(data => {
                console.log('Form submission confirmed:', data);
                posthog.capture('form_submission', { input1, input2: formattedInput2, status: 'Success' }); // Track successful form submission with input1 and input2
                incrementUsageCount();  // Increment usage count
                onSubmit(submissionId);  // Pass the submission ID to the parent component
                setIsLoading(false); // Set loading state to false after submission
            })
            .catch((error) => {
                console.error('Error:', error);
                setIsLoading(false); // Set loading state to false if there is an error
                toast.error('An error occurred during form submission. Please try another website.'); // Show error toast
            });
        } else {
            setIsModalOpen(true);  // Show the modal if usage limit is reached
        }
    };

    const formatURL = (url) => {
        console.log('Original URL:', url);
        // Check if the URL starts with http://, https://, or www.
        if (!url.match(/^https?:\/\//)) {
            if (url.startsWith('www.')) {
                const formattedUrl = `https://${url}`;
                console.log('Formatted URL:', formattedUrl);
                return formattedUrl;
            } else {
                const formattedUrl = `https://www.${url}`;
                console.log('Formatted URL:', formattedUrl);
                return formattedUrl;
            }
        }
        console.log('No Formatting Needed URL:', url);
        return url;
    };

    return (
        <div className='form-wrap'>
            <form onSubmit={handleSubmit} className='main-form'>
                <input
                    type='text'
                    required
                    placeholder='Company name'
                    className='form-input'
                    value={input1}
                    onChange={(e) => setInput1(e.target.value)}
                />
                <input
                    type='text'
                    required
                    placeholder='Website URL'
                    className='form-input'
                    value={input2}
                    onChange={(e) => setInput2(e.target.value)}
                />
                <button type='submit' className='main-button' disabled={isLoading}>
                    {isLoading ? (
                        <div className='load-wrap'>
                            <div className="loader"></div>
                            Loading...
                        </div>
                        
                    ) : (
                        <>
                            <img className='n-icon' src={sparkles} alt='sparkles' />
                            Generate Icebreaker
                        </>
                    )}
                </button>
            </form>
            <Modal 
                isOpen={isModalOpen} 
                onClose={() => setIsModalOpen(false)} 
                message="Get 10x more open rate and increase sales with Pro features" 
            /> {/* Render the modal */}
        </div>
    );
}

export default FormComponent;