import React, { useState, useEffect } from 'react';
import './App.css';
import copyicon from "./img/copyicon.svg";
import logoicon from "./img/icebreaker_icon.png";
import arrowicon from "./img/arrowicon.svg";
import Output from './Components/Output';
import FormComponent from './Components/Form';
import { Toaster } from 'sonner';
import useUsageCount from './hooks/useUsageCounts';
import Modal from './Components/Modal';
import { usePostHog } from 'posthog-js/react'; // Import usePostHog

function App() {
    const posthog = usePostHog(); // Get the PostHog instance
    const [submissionId, setSubmissionId] = useState('');
    const [showOutput, setShowOutput] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [usageCount, incrementUsageCount] = useUsageCount();
    const usageLimit = 3;

    useEffect(() => {
        posthog.capture('$pageview'); // Track page views
    }, [posthog]);

    const handleFormSubmit = (id) => {
        setSubmissionId(id);
        setShowOutput(true);
        incrementUsageCount();
        posthog.capture('form_submitted'); // Track form submission
    };

    const handleNewIcebreaker = () => {
        setSubmissionId('');
        setShowOutput(false);
    };

    const handleRegenSubmit = () => {
        if (usageCount < usageLimit) {
            incrementUsageCount();
            setShowOutput(false);
            setTimeout(() => {
                setShowOutput(true);
            }, 100);
        } else {
            setIsModalOpen(true);
        }
    };

    const handleRegisterClick = () => {
        posthog.capture('register_button_click', { source: 'navigation' }); // Track register button clicks
    };

    return (
        <div className="wrap">
            <Toaster position='top-right' richColors />
            <div className='nav'>
                <a className='logo' href='https://www.icebreaker.email'>
                    <img className='l-icon' src={logoicon} alt='sparkles' />
                    Icebreaker
                </a>
                <div className='right-nav'>
                    <span className='usage'>Usage Count: {usageCount}</span>
                    <a className='main-button' href='https://app.icebreaker.email/' onClick={handleRegisterClick}>Register</a>
                </div>
            </div>
            <div className="main-content">
                <a className='blob' href='https://app.icebreaker.email/'>
                    Icebreaker Launch
                    <img className='a-icon' src={arrowicon} alt='arrow' />
                </a>
                <h1 className="main-head">Generate an email icebreaker in seconds</h1>
                <p className='p-s'>
                    To generate your icebreaker, input the company name and website url
                </p>
                {!showOutput && <FormComponent onSubmit={handleFormSubmit} incrementUsageCount={incrementUsageCount} usageCount={usageCount} usageLimit={usageLimit} />}
                {showOutput && (
                    <Output 
                        submissionId={submissionId} 
                        copyIcon={copyicon} 
                        onNewIcebreaker={handleNewIcebreaker} 
                        onRegenSubmit={handleRegenSubmit} 
                    />
                )}
                <Modal 
                    isOpen={isModalOpen} 
                    onClose={() => setIsModalOpen(false)} 
                    message="You have reached the usage limit for generating icebreakers."
                />
            </div>
            <div className='second-content'>
                <h2 className='second-head'>Boost Your Email Engagement with an Email Icebreaker Generator</h2>
                An email icebreaker generator is a powerful software tool or application designed to enhance your email communication by providing engaging and customizable introductory phrases or sentences. These icebreakers are crafted to grab attention and establish a positive tone from the outset, making it easier to connect with your recipients and encourage them to read the rest of your email.
                <h3 className='head3'>Why Use an Email Icebreaker Generator?</h3>
                <ul className='ben-list'>
                    <li className='ben-item'><b>Save Time and Effort:</b> Perfect for professionals who send numerous emails daily, this tool offers creative and effective ways to start your emails, especially when writer’s block strikes.</li>
                    <li className='ben-item'><b>Variety of Scenarios:</b> The generator provides a range of pre-written icebreakers tailored to different scenarios, contexts, and relationships. Whether you need a casual, friendly tone or a professional, formal approach, there’s an icebreaker for every occasion.</li>
                    <li className='ben-item'><b>Boost Engagement:</b> By starting your emails on a personable note, you increase the likelihood of your message being read and responded to. This can enhance the quality and impact of your communication.</li>
                    <li className='ben-item'><b>Customizable Options:</b> Many generators allow for customization, enabling you to tweak the icebreakers to better fit your unique voice and the specific context of your email.</li>
                </ul>
                <h4 className='head3'>How an Email Icebreaker Generator Works</h4>
                <ol className='ben-list'>
                    <li className='ben-item'><b>Paste a URL:</b> Input the website URL of the company you want to generate an icebreaker for</li>
                    <li className='ben-item'><b>Prepare for the Icebreaker generation:</b> Make sure that the inputted URL is correct and isn't a link to social media</li>
                    <li className='ben-item'><b>Incorporate into Your Email:</b> Copy the generated icebreaker and paste it into your email to start your message on the right foot.</li>
                </ol>
                <h5 className='head3'>Who Can Benefit from an Email Icebreaker Generator?</h5>
                <ul className='ben-list'>
                    <li className='ben-item'><b>Business Professionals:</b> Save time and increase the effectiveness of your email communication with clients, colleagues, and partners.</li>
                    <li className='ben-item'><b>Sales and Marketing Teams:</b> Capture your recipient’s attention from the start, increasing the chances of a successful pitch or outreach.</li>
                    <li className='ben-item'><b>Job Seekers and Networkers:</b> Stand out in crowded inboxes with a compelling and personalized introduction.</li>
                </ul>
                Enhance your email strategy today with an email icebreaker generator and experience the difference in your communication effectiveness. Whether for personal or professional use, this tool is your go-to solution for creating engaging and memorable email introductions.
            </div>
            <div className='overlay'></div>
        </div>
    );
}

export default App;