import React, { useState, useEffect, useRef } from 'react';
import Typed from 'typed.js';
import copyicon from "../img/copyicon.svg";
import regenicon from "../img/regenicon.svg";
import { toast } from 'sonner';
import posthog from 'posthog-js';

function Output({ submissionId, onNewIcebreaker, onRegenSubmit }) {
    const [outputData, setOutputData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const typedOutputRef = useRef(null);
    const typedInstanceRef = useRef(null);

    useEffect(() => {
        if (submissionId) {
            setLoading(true);
            setOutputData(null);
            setErrorMessage('');

            fetch(`/data/${submissionId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => res.json())
            .then((data) => {
                setLoading(false);
                if (data.status === 'success') {
                    if (data.data.output && data.data.output.icebreaker) {
                        setOutputData(data.data.output.icebreaker); // Accessing the icebreaker under output
                    } else {
                        setErrorMessage('No data was fetched. This might happen due to an issue with the provided website.');
                        toast.error('No data was fetched for the provided submission ID.');
                    }
                } else {
                    setErrorMessage('Failed to retrieve data. Please try again.');
                    toast.error('Failed to retrieve data.');
                }
            })
            .catch((error) => {
                console.error("Error fetching data: ", error);
                setLoading(false);
                setErrorMessage('Failed to fetch data. Please try again.');
                toast.error('Failed to fetch data. Please try again.');
            });
        }
    }, [submissionId]);

    useEffect(() => {
        if (outputData) {
            const options = {
                strings: [outputData],
                typeSpeed: 50,
                backSpeed: 25,
                loop: false,
                showCursor: false
            };

            if (typedInstanceRef.current) {
                typedInstanceRef.current.destroy(); // Destroy previous instance before creating a new one
            }

            typedInstanceRef.current = new Typed(typedOutputRef.current, options);

            return () => {
                typedInstanceRef.current.destroy(); // Clean up on unmount
            };
        }
    }, [outputData]);

    const copyToClipboard = () => {
        if (outputData) {
            navigator.clipboard.writeText(outputData)
                .then(() => {
                    toast.success('Text copied to clipboard');
                    posthog.capture('Copy to Clipboard', { submissionId });
                })
                .catch((err) => {
                    console.error("Failed to copy text: ", err);
                    toast.error('Failed to copy text');
                });
        }
    };

    if (loading) {
        return <div className='output'>Loading...</div>; // Show loading indicator while fetching data
    }

    return (
        <div className='output'>
            {errorMessage ? (
                <div className='output'>
                <div className='output-div'>
                    <p className='error-message'>{errorMessage}</p>
                    <div className='command-wrap'>
                            <img className='com-icon' src={regenicon} alt='Regenerate icebreaker' onClick={onRegenSubmit} style={{ cursor: 'pointer' }} />
                    </div>
                </div>
                <button className='main-button' onClick={onNewIcebreaker}>New Icebreaker</button> {/* New Icebreaker button */}
                </div>
            ) : (
                outputData && (
                    <div className='output'>
                    <div className='output-div'>
                        <span ref={typedOutputRef}></span> {/* Container for the typed.js animation */}
                        <div className='command-wrap'>
                            <img className='com-icon' src={copyicon} alt='Copy to clipboard' onClick={copyToClipboard} style={{ cursor: 'pointer' }} />
                            <img className='com-icon' src={regenicon} alt='Regenerate icebreaker' onClick={onRegenSubmit} style={{ cursor: 'pointer' }} />
                        </div>
                    </div>
                    <button className='main-button' onClick={onNewIcebreaker}>New Icebreaker</button> {/* New Icebreaker button */}
                    </div>
                )
            )}
        </div>
    );
}

export default Output;
